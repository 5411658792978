<style scoped lang='scss'>
.image_recognition_title{
  font-size: 22px;
  font-weight: 400;
  color: #2574b0;
  line-height: 1;
  margin-top: 60px;
  margin-bottom: 20px;
}
.VividOS_ul li{
  margin-bottom: 20px;
  font-size: 16px;
  padding-left: 20px;
  position: relative;
}
.VividOS_ul li span{
  width: 12px;
  height: 12px;
  background-color: #54bbff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: calc(50% - 6px);
}
</style>

<template>
  <div id="image_recognition">
    <div class="width rullMain">
      <div class="title textCenter weight">图像识别解决方案</div>
      <img src="@/assets/images/business/2/sol_img_main.png" alt="">
      <div>
        <div class="image_recognition_title">正在开发图像识别SDK套件</div>
        <ul class="VividOS_ul">
          <li><span></span>提供使用KPU（Knowledge Processing Unit）的物体识别模型</li>
          <li><span></span>可以识别20种物体。识别精度在90%到95%之间</li>
          <li><span></span>搭载实时OS</li>
          <li><span></span>实时识别图像尺寸320X240</li>
          <li><span></span>为客户提供的学习数据提供帮助调整工作</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacy",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    window.scrollTo({top: 0,left: 0});
  },
  methods: {},
};
</script>
